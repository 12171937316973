@import "./src/styles/shared";

.icon {
  display: inline-block;
  vertical-align: middle;
  fill: $color-primary;

  &--small {
    height: 1rem;
    width: 1rem;
  }

  &--medium {
    height: 1.25rem;
    width: 1.25rem;
  }

  &--larger {
    height: 1.75rem;
    width: 1.75rem;
  }

  &--large {
    height: 2rem;
    width: 2rem;
  }
}
