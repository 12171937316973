$color-bg: #F4F6F9;
$color-white: #FFFFFF;
$color-border: #E1E1E1;
$color-grey-100: #F8F8F8;
$color-grey-200: #F3F2F2;
$color-grey-800: #3E3E3C;
$color-grey-900: #080707;
$color-primary: #FF2D78;
$color-secondary-green: #4FB56B;
$color-secondary-orange: #EBBD65;
$color-secondary-red: #E0534D;

$color-score-a: $color-secondary-green;
$color-score-b: #7BBC8D;
$color-score-c: #EBC884;
$color-score-d: $color-secondary-orange;
$color-score-e: #EB9565;
$color-score-f: $color-secondary-red;

$z-index-dropdown:       1000;
$z-index-sticky:         1020;
$z-index-fixed:          1030;
$z-index-modal-backdrop: 1040;
$z-index-off-canvas:     1050;
$z-index-modal:          1060;
$z-index-popover:        1070;
$z-index-tooltip:        1080;

$navbar-height: 5rem;
$border-radius-md: 8px;

// Spacing
$spacings: ();
@for $i from 1 through 12 {
  $spacings: map-merge($spacings, ($i: $i * 6px));
}

:export {
  colorGrey200: $color-grey-200;
  colorScoreA: $color-score-a;
  colorScoreB: $color-score-b;
  colorScoreC: $color-score-c;
  colorScoreD: $color-score-d;
  colorScoreE: $color-score-e;
  colorScoreF: $color-score-f;
  borderRadiusMd: $border-radius-md;
}
