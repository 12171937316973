@import "./src/styles/shared";

.DashboardCards {
  display: grid;
  grid-gap: space(4);
  grid-template-columns: repeat(auto-fill, minmax(max(280px, 30%), 1fr));
}

.DashboardCard {
  background: $color-white;
  box-shadow: 0 8px 57px rgba(0, 75, 159, 0.11);
  border-radius: 20px;
  padding: space(4) space(3) space(3);
  display: flex;
  flex-direction: column;
}

.DashboardCardHeader {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.75rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: $color-grey-800;
}

.DashboardCardHeaderIcon {
  margin-right: space(1);
}
