@import "./src/styles/shared";

.Navbar {
  position: fixed;
  z-index: $z-index-sticky;
  height: $navbar-height;
  top: 0;
  left: 0;
  right: 0;
  background: $color-white;
  padding: space(4);
  display: flex;
  align-items: center;
}

.NavbarLogo {
  width: 8rem;
}
