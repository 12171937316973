@import "./src/styles/shared";

.LoginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: $color-bg;
}

.LoginCard {
  background: $color-white;
  border: 1px solid $color-border;
  border-radius: $border-radius-md;
  padding: 40px 100px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    &:not(:first-child) {
      margin-top: space(5);
    }
  }
}

.LoginLogo {
  width: 138px;
}

.LoginButtons {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  :global(.slds-button) {
    margin: 0;

    &:not(:first-child) {
      margin-top: space(2);
    }
  }
}
