@import "./src/styles/shared";

.GroupProgress {
  margin: space(4) 0 space(5);
}

.ScoreDetailsList {
  display: flex;
  flex-direction: column;

  > * {
    margin-top: space(1);

    &:first-child {
      margin-top: 0;
    }
  }
}

.ScoreDetail {
  display: flex;
  align-items: flex-start;
  background: $color-grey-100;
  padding: space(3) space(1) space(3) space(3);
  color: $color-grey-900;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: $border-radius-md;
}

svg.ScoreDetailIcon {
  flex: 0 0 auto;
  margin-right: space(3);

  &Pass {
    fill: $color-score-a;
  }
  &Warning {
    fill: $color-score-d;
  }
  &Danger {
    fill: $color-score-f;
  }
}

.GroupScoreLabel {
  font-size: 1rem;
  color: $color-grey-800;
}

.GroupScoreGrade {
  font-size: 1.5rem;
  font-weight: 500;
}

.GroupScoreBadge {
  fill: $color-grey-100;
}

