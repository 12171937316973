@import "./src/styles/shared";

.DashboardFooter {
  font-size: 1.25rem;
  font-weight: 400;
  color: $color-grey-900;
  margin: space(7) 0 space(5);
}

.DashboardFooterOrg {
  font-weight: 500;
}
