@import "./src/styles/shared";

.TotalScoreContainer {
  position: relative;
  align-self: center;
  width: 100%;
  max-width: 100%;
}

.TotalScoreChart {
  max-width: 100%;
}

.TotalScoreLabel {
  font-size: 1.125rem;
  color: $color-grey-800;
}

.TotalScoreGrade {
  font-size: 2rem;
  font-weight: 500;
}

.TotalScoreBadge {
  fill: $color-grey-100;
}
