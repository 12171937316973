@import "./src/styles/shared";

.DashboardOverlay {
  position: fixed;
  top: $navbar-height;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparentize($color-white, 0.1);
  backdrop-filter: blur(52px);
  z-index: $z-index-fixed;
  cursor: wait;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.DashboardOverlayLabel {
  margin-top: space(3);
}
