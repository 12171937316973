@import "./src/styles/shared";

.Spinner {
  width: 3rem;
  height: 3rem;
}

.SpinnerLogo {
  animation: logo-spinner 2s linear infinite;
}

@keyframes logo-spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
