@import "./src/styles/shared";

.Container {
  width: 100%;
  min-height: 100vh;
  padding: space(4);
}

.Container_WithNav {
  margin-top: $navbar-height;
  min-height: calc(100vh - #{$navbar-height});

  background-image: url("../../images/background.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center $navbar-height;
  background-size: cover;
}
