@import "./src/styles/shared";

.DashboardHeader {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: -0.5px;
  color: $color-grey-900;
  margin: space(4) 0 space(6);
}

.DashboardHeader_Primary {
  color: $color-primary;
}
